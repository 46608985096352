//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex'
import { i18n } from '../../ext/i18n'
import modal_mixin from './modal/modal-mixin'
import { CHATS, USERDATA, AJAX } from '../store/modulesNames'
import { GET_SEND_BY_ENTER, GET_AJAXX_CALL_PROGRESS_BY_FILE } from '../store/gettersTypes'
import { ACT_SEND_FILE_MESSAGE } from '../store/actionsTypes'

import CustomTextarea from './custom/CustomTextarea.vue'
import CustomCheckbox from './custom/CustomCheckboxRound.vue'
import ProgressCircle from './ProgressCircle.vue'

export default {
    name: 'loader-files',
    props: [
        'cid',
        'cidType',
        'files',
        'input',
        'fileGroupId',
        'enc'
    ],
    components: {
        CustomTextarea,
        CustomCheckbox,
        ProgressCircle,
    },
    mixins: [modal_mixin],
    data() {
        return {
            imagesScroll: false,
            text: '',
            focus: true,
            dropFile: false,
            filesEmpty: false,
            isGroupFiles: false,
            filesArr: [],
            previewFiles: [],
            nonIvFiles: [],
            ivFiles: [],
            isShowGroupCheckBox: false,
            currentUploadFileName: '',
            dragEntercssClass: 'tmp',
            isSending: false,
            compModalProps: {
              name: 'loader-files',
              delay: 200,
              pivotY: 0.2,
              width: 400,
              height: 'auto'
            }
        }
    },
    async created() {
        this.filesArr = this.files
        window.addEventListener('resize', this.doLoad);
        this.previewFiles = await this.setPreviewFiles()
        this.setNonIVFiles()
        this.setIVFiles()
    },
    destroyed() {
        window.removeEventListener('resize', this.doLoad)
    },
    watch: {
        files() {
            this.doLoad()
            if(this.filesEmpty && this.pureFilesList.length) {
                this.filesEmpty = false
            }
        },
    },
    computed: {
        pureFilesList() {
            if (!this.enc) return this.filesArr.filter((file) => {
                return Boolean(file)
            })
            else return this.filesArr.filter((file) => this.isFileEncryptionPossible(file.type))
        },
        isSendByEnter() {
            return this.$store.getters[`${USERDATA}/${GET_SEND_BY_ENTER}`]
        },
        getText() {
            return this.text.trim()
        },
        onlyImageVideo() {
            for(let i = 0; i < this.pureFilesList.length; i++) {
                const isAVMediaFile = this.pureFilesList[i].type.indexOf('image') > -1 || this.pureFilesList[i].type.indexOf('video') > -1
                if(!isAVMediaFile) return false
            }
            return true
        },
        styles() {
            const styles = {}
            if (!this.isGroupFiles) Object.assign(styles, {'--preview-width': '100%'})
            return styles
        },
        // uploadProgress() {
        //     //if (!this.currentUploadFileName) return
        //     console.log("🚀 ~ file: LoaderFiles.vue:184 ~ uploadProgress ~ this[GET_AJAXX_CALL_PROGRESS_BY_FILE](this.currentUploadFileName):", this[GET_AJAXX_CALL_PROGRESS_BY_FILE](this.currentUploadFileName))
        //     return this[GET_AJAXX_CALL_PROGRESS_BY_FILE](this.currentUploadFileName)
        // },
        // ...mapGetters(AJAX, [GET_AJAXX_CALL_PROGRESS_BY_FILE]),
    },
    methods: {
        async setPreviewFiles() {
            let result = [];
            for(let i = 0; i < this.pureFilesList.length; i++) {
                let obj = {};
                let type = this.pureFilesList[i].type.split('/');
                let size = formatSize(this.pureFilesList[i].size);
                if(type[0] === 'image') obj.img = await getImage(this.pureFilesList[i])
                if(type[0] === 'video') obj.img = await getVideoPreviewImage(this.$store, this.pureFilesList[i])
                obj.name = this.pureFilesList[i].name;
                obj.type = type[0];
                obj.size = size;
                result.push(obj);
            }
            return result

            function formatSize(byte){
                let locale = i18n.messages[i18n.locale]
                let i = 0, type = [locale.b, locale.kb, locale.mb, locale.gb, locale.tb, locale.pb];
                while((byte / 1000 | 0) && i < type.length - 1) {
                    byte /= 1024;
                    i++;
                }
                return byte.toFixed(2) + ' ' + type[i];
            }
            function getImage(file) {
                return new Promise((resolve, reject) => {
                    let reader = new FileReader()
                    reader.readAsDataURL(file)
                    reader.onloadend = function() {
                        let img = document.createElement('img')
                        img.src = reader.result
                        resolve(img)
                    }
                })
            }
            function getVideoPreviewImage(store, file) {
                return new Promise((resolve, reject) => {
                    let reader = new FileReader()
                    reader.readAsDataURL(file)
                    reader.onloadend = async function() {
                        try {
                            const result = reader.result.replace('video/x-matroska', 'video/mp4')
                            const videoImgPreview = await store.dispatch('data_message_builder/actGetVideoImgPreview', result)
                            let img = document.createElement('img')
                            img.src = "data:image/png;base64," + videoImgPreview.preview
                            resolve(img)
                        } catch(e) {
                            console.log("🚀 ~ file: LoaderFiles.vue:238 ~ setPreviewFiles ~ e:", e)
                        }
                    }
                })
            }
        },
        setNonIVFiles() {
            if (this.enc) {
                this.nonIvFiles = []
                return
            }
            const nIvFiles = this.previewFiles.filter(f => !['image','video'].includes(f.type))
            this.nonIvFiles = nIvFiles
        },
        setIVFiles() {
            const ivFiles = this.previewFiles.filter(f => ['image','video'].includes(f.type))
            this.ivFiles = ivFiles
            this.isShowGroupCheckBox = ivFiles.length > 3
        },
        close() {
            this.$modal.hide('loader-files')
        },
        async send() {
            this.isSending = true
            if(!this.pureFilesList.length) {
                this.filesEmpty = true;
                return;
            }

            let filesToSend = this.pureFilesList.filter(f => {
                return this.previewFiles.some(pf => pf.name === f.name)
            })
            // console.log("🚀 ~ filesToSend ~ this.previewFiles:", this.previewFiles)
            // console.log("🚀 ~ filesToSend ~ filesToSend:", filesToSend)

            const chat = this.$store.getters['chats/selected']
            let cid = chat && chat.cid
            let cidType = chat && chat.cidType
            let fileGroupId = ''
            if (this.isGroupFiles) {
                if (this.fileGroupId) fileGroupId = this.fileGroupId
                else fileGroupId = Date.now().toString(36)
            }
            const lastFile = filesToSend[filesToSend.length -1]
            if (!fileGroupId) {
                await Promise.all(filesToSend.map(async (file) => {
                    this.currentUploadFileName = file.name
                    if (file !== lastFile) {
                        await this[ACT_SEND_FILE_MESSAGE]({cid, cidType, file, enc: this.enc})
                    }
                }))
                await this[ACT_SEND_FILE_MESSAGE]({cid, cidType, file: lastFile, text: this.getText, enc: this.enc})
            } else {
                await Promise.all(filesToSend.map(async (file) => {
                    const fileType = file.type.split('/')[0]
                    const isAVFile = ['image','video'].includes(fileType)
                    if (file.name !== lastFile.name) {
                        if (isAVFile)
                            await this[ACT_SEND_FILE_MESSAGE]({cid, cidType, file, fileGroupId, enc: this.enc})
                        else
                            await this[ACT_SEND_FILE_MESSAGE]({cid, cidType, file, enc: this.enc})
                    }
                }))
                const fileType = lastFile.type.split('/')[0]
                const isAVFile = ['image','video'].includes(fileType)
                if (isAVFile) {
                    await this[ACT_SEND_FILE_MESSAGE]({cid, cidType, file: lastFile, text: this.getText, fileGroupId, enc: this.enc})
                } else await this[ACT_SEND_FILE_MESSAGE]({cid, cidType, file: lastFile, text: this.getText, enc: this.enc})
            }
            this.isSending = false
            this.close()
        },
        isFileEncryptionPossible(fileType) {
            let possibleTypes = [
                /video\/*/,
                /image\/*/,
                /application\/pdf/,
            ]
            return !!possibleTypes.find(reg => reg.test(fileType))
        },
        removeItem(index) {
            this.previewFiles.splice(index, 1)
            if (this.previewFiles.length < 4) this.isGroupFiles = false
            this.setNonIVFiles()
            this.setIVFiles()
            if (!this.previewFiles.length) this.close()
        },
        doLoad() {
            if(!this.$refs.previewImages) return
            if(this.$refs.previewImages.clientHeight !== this.$refs.previewImages.scrollHeight) this.imagesScroll = true;
            else this.imagesScroll = false;
            this.focusInput()
        },
        focusInput() {
            const inputElem = this.$refs.inputElem
            const cta = inputElem && inputElem.$refs.custom_input_textarea
            if (cta) cta.focus()                
        },
        dragenter(e) {
            e.preventDefault()
            e.stopPropagation()
            const cssClass = e.target.className
            this.dragEntercssClass = cssClass
            if (cssClass === 'delete-btn-img') return
            const isPreviewDrop = cssClass === 'preview'
            if (!isPreviewDrop) {
                if (this.dropFile) return
                if (e.dataTransfer.types) {
                    for (let i = 0; i < e.dataTransfer.types.length; i++) {
                        if (e.dataTransfer.types[i] == "Files") {
                            this.dropFile = true
                        }
                    }
                }
                return 
            }
        },
        dragover(e) {
            e.preventDefault()
            e.stopPropagation()
        },
        dragleave(e) {
            e.preventDefault()
            e.stopPropagation()
            const cssClass = e.target.className
            if (cssClass === 'delete-btn-img') return
            const isPreviewDrop = cssClass === 'add-files'
            if (isPreviewDrop) {
                if (this.dropFile) setTimeout(() => { 
                    if (this.dragEntercssClass) this.dropFile = false 
                }, 100 ) 
                return 
            }
        },
        async drop(e) {
            e.preventDefault();
            e.stopPropagation();

            for(let i = 0; i < e.dataTransfer.files.length; i++) {
                this.filesArr.push(e.dataTransfer.files[i]);
            }
            this.previewFiles = await this.setPreviewFiles()
            this.dropFile = false
            this.isDroppingFile = false
        },
        ...mapActions(CHATS, [ACT_SEND_FILE_MESSAGE])
    }
}
