//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex'
import { CHAT, CONTACTS, USERDATA } from "../../store/modulesNames"
import { ACT_CHAT_REMOVE_MSG, ACT_CHAT_REMOVE_MSGS } from "../../store/actionsTypes"
import modal_mixin from "./modal-mixin"
import ModalDialog from "./ModalDialog.vue"
import CustomRadio from '../custom/CustomRadio.vue'
import { GET_MERGED_CONTACT_BY_ID, GET_UID } from "../../store/gettersTypes"

export default {
    name: "DelChatMessage",
    mixins: [modal_mixin],
    components: { ModalDialog, CustomRadio },
    props: {
        msg: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            compModalProps: {
                delay: 200,
                pivotY: 0.45,
                height: 'auto',
                adaptive: true,
                width: 340,
                styles: "min-width: 250px;"                    
            },

            title: this.$t('action-confirm'),
            text: this.$t('modal.delete-message-confirm'),
            forme: true,
            everyone: false,
            radioItems: [
                {
                    value: true,
                    label: this.$t('modal.delete-message-for-me')
                }, {
                    value: false,
                    label: this.$t('modal.delete-message-for-all')
                },
            ]
        }
    },
    computed: {
        isGroupChat() {
            return this.msg.cidType === 'group'
        },
        ownChat() {
            return !this.isGroupChat && this.msg.cid === this[GET_UID]
        },
        ownMsg() {
            return this.msg.senderId === this[GET_UID]
        },
        canDeleteForAll() {
            return this.ownMsg && !this.ownChat
        },
        ...mapGetters(USERDATA, [GET_UID]),
        ...mapGetters(CONTACTS, [GET_MERGED_CONTACT_BY_ID]),
    },
    watch: {
        forme(val) {
            this.everyone = !val
        },
    },
    methods: {
        onOk() {
            const message = this.msg
            const isGroupMsgs = message.hasOwnProperty('groupMsgs')
            if (isGroupMsgs) {
                this[ACT_CHAT_REMOVE_MSGS]({msgs: message.groupMsgs, everyone: this.everyone })
            } else this[ACT_CHAT_REMOVE_MSG]({ msg: this.msg, everyone: this.everyone })
            this.modalClose()
            this.$nextTick(() => {
                this.btnOkCb && this.btnOkCb()
                this.props.btnOk && this.props.btnOk.cb && this.props.btnOk.cb()
            })
        },
        onCancel() {
            this.modalClose();
        },
        ...mapActions(CHAT, [ACT_CHAT_REMOVE_MSG, ACT_CHAT_REMOVE_MSGS])
    }
}
